import React from "react";
import { Link } from "gatsby";
import logo from "../images/logo.png";
import apg from "../images/apg.gif";

const Footer = () => (
    <footer className="bg-green-dark text-white font-sans py-12">
        <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col md:flex-row justify-center items-center bg-green-main rounded-lg mx-8 p-6 mb-12 text-center -mt-20 shadow-2xl xl:mx-40">
                <h3>Let's talk about your story.</h3>
                <Link to="/contact-me" className="border border-white rounded-full px-8 py-2 mt-4 md:ml-6 md:mt-0 leading-none hover:bg-white hover:text-green-main">Contact Me!</Link>
            </div>
            <nav className="text-sm font-normal text-center">
                <Link to="/" className="p-2 md:p-4 hover:underline">Home</Link>
                <Link to="/services" className="p-2 md:p-4 hover:underline">Services</Link>
                <Link to="/faq" className="p-2 md:p-4 hover:underline">FAQ</Link>
                <Link to="/about-me" className="p-2 md:p-4 hover:underline">About Me</Link>
                <Link to="/contact-me" className="p-2 md:p-4 hover:underline">Contact Me</Link>
            </nav>
            <div className="flex flex-col items-center">
                <img className="px-4 md:px-0 pt-4 md:h-64" src={logo} alt="Relative Interest" />
                <img className="w-16 pt-4" src={apg} alt="APG Member" />
            </div>
            <div className="mt-8 text-xs md:flex md:items-center md:justify-between md:px-24 lg:px-40">
                <div className="flex justify-center md:justify-start md:order-2">
                    <p className="inline-flex text-center text-base leading-6 text-gray-400">
                        Made with
                        <svg className="h-6 w-6 mx-1" fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clipRule="evenodd"></path></svg>
                        by
                        <a className="pl-1 hover:underline" href="https://jarom.dev/?ref=relativeinterest" target="_blank" rel="noopener noreferrer">Jarom Rial</a>
                    </p>
                </div>
                <p class="mt-8 text-base text-center md:text-left leading-6 text-gray-400 md:mt-0 md:order-1">
                    &copy; {new Date().getFullYear()} Relative Interest
                </p>
            </div>
        </div>
    </footer>
);

export default Footer