import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Transition } from "@headlessui/react"

const Header = ({ siteTitle }) => {
    const [mobileIsOpen, setMobileIsOpen] = useState(false);
    return (
        <header className="bg-isabelline text-green-main font-sans z-20">
            <div className="max-w-8xl mx-auto px-4 sm:px-6">
                <div className="flex justify-between items-center border-b-2 border-green-main border-opacity-5 py-6 md:justify-start md:space-x-10">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/" className="font-bold text-xl">
                            <span className="sr-only">{siteTitle}</span>
                            <span>{siteTitle}</span>
                        </Link>
                    </div>
                    <div className="-mr-2 -my-2 md:hidden">
                        <button type="button" onClick={() => { setMobileIsOpen(true) }} className="bg-isabelline rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Open menu</span>
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                    <nav className="hidden md:flex space-x-10">
                        <Link className="text-base font-medium text-gray-500 hover:text-green-dark" to="/services">Services</Link>
                        <Link className="text-base font-medium text-gray-500 hover:text-green-dark" to="/faq">FAQ</Link>
                        <Link className="text-base font-medium text-gray-500 hover:text-green-dark" to="/about-me">About Me</Link>
                        <Link className="text-base font-medium text-gray-500 hover:text-green-dark" to="/contact-me">Contact Me</Link>
                    </nav>
                </div>
            </div>

            <Transition
                show={mobileIsOpen}
                enter="duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div className="pt-5 pb-6 px-5">
                            <div className="flex items-center justify-between">
                                <div className="font-bold text-xl">
                                    <span>{siteTitle}</span>
                                </div>
                                <div className="-mr-2">
                                    <button type="button" onClick={() => { setMobileIsOpen(false) }} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                        <span className="sr-only">Close menu</span>
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div className="mt-6">
                                <nav className="grid gap-y-8">
                                    <Link to="/services" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="ml-3 text-base font-medium text-gray-900">Services</span>
                                    </Link>

                                    <Link to="/faq" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="ml-3 text-base font-medium text-gray-900">FAQ</span>
                                    </Link>

                                    <Link to="/about-me" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="ml-3 text-base font-medium text-gray-900">About Me</span>
                                    </Link>

                                    <Link to="/contact-me" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                                        <span className="ml-3 text-base font-medium text-gray-900">Contact Me</span>
                                    </Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
